/* eslint-disable */
import {
  Menu,
  Badge,
  MenuItem,
  IconButton,
  ListItemIcon,
  Snackbar,
  Alert,
  SnackbarCloseReason,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { UserService } from '../../services/user/user.service';
import styles from './notifications.module.scss';
import { useNotificationData } from '../../contexts/notification-data.context';

interface Props {
  iconColor?: string;
}

function Notifications(props: Props): JSX.Element {
  const { count: notificationsCount, setCount: setNotificationsCount } =
    useNotificationData();

  const [notifications, setNotifications] = React.useState<any>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open1 = Boolean(anchorEl);
  const MINUTE_MS = 60000;

  const handleCloseNotification = () => {
    setAnchorEl(null);
  };

  const handleClickNotification = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseNotificationMenuItem = (event: any) => {
    const { myValue } = event.currentTarget.dataset;
    readNotification(myValue);
  };

  const readNotification = async (notificationId: string) => {
    try {
      await new UserService().readNotification(notificationId);
      fetchNotifications();
      fetchNotificationsCount();
    } catch (error) {
      console.error(error);
    }
  };
  const [open, setOpen] = React.useState(false);
  const [messageNotification, setMessageNotification] = React.useState('');

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const fetchNotifications = async () => {
    try {
      const result = await new UserService().fetchNotifications();
      // create notification alert from the first notification.
      console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', result[0]);
      if (result[0].metaData.TYPE === 'USER_ONBOARDING_NOTIFICATION') {
        setMessageNotification(result[0].message);
        setOpen(true);
      }
      setNotifications(result);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchNotificationsCount = async () => {
    try {
      const result = await new UserService().fetchNotificationsCount();
      setNotificationsCount(result.count);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchNotifications();
    fetchNotificationsCount();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchNotifications();
      fetchNotificationsCount();
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, []);

  const { iconColor } = props;

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={20000}
        onClose={handleClose}
        style={{
          background: 'white',
          border: '1px solid',
          padding: '20px',
          borderRadius: '10px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div
            style={{
              maxWidth: '350px',
            }}
          >
            {messageNotification}
          </div>
          <div
            style={{
              maxWidth: '350px',
              cursor: 'pointer',
            }}
            onClick={handleClose}
          >
            close
          </div>
        </div>
      </Snackbar>
      <Badge
        badgeContent={notificationsCount}
        color="secondary"
        className={styles.badgeClassStyles}
      >
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls="long-menu"
          aria-expanded={open1 ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleClickNotification}
          className={styles.iconButtonNotificationClass}
          size="large"
        >
          <span className="material-icons">notifications</span>
        </IconButton>
      </Badge>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseNotification}
        PaperProps={{
          style: {
            maxHeight: 100 * 4.5,
            width: '300px',
          },
          className: styles.notificationContainer,
        }}
      >
        <div>
          {notifications.length === 0 ? (
            <div
              style={{ padding: '16px', display: 'flex', alignItems: 'center' }}
            >
              <span className={`material-icons ${styles.iconMaterialDesign}`}>
                error
              </span>
              No New Notifications
            </div>
          ) : (
            notifications.map((option: any) => (
              <MenuItem
                key={option._id}
                data-my-value={option._id}
                style={{
                  whiteSpace: 'normal',
                  backgroundColor: !option.isRead ? '#e3f4ff' : 'white',
                  alignItems: 'center',
                  borderBottom: '1px solid #538df350',
                }}
                onClick={handleCloseNotificationMenuItem}
              >
                {option.isRead ? (
                  <ListItemIcon>
                    <span className="material-icons">mark_email_read</span>
                  </ListItemIcon>
                ) : (
                  <ListItemIcon>
                    <span className="material-icons">mark_email_unread</span>
                  </ListItemIcon>
                )}
                <p className={styles.notificationContent}>{option.message}</p>
              </MenuItem>
            ))
          )}
        </div>
      </Menu>
    </>
  );
}

Notifications.defaultProps = {
  iconColor: 'white',
};

export default Notifications;
